import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Tech Studio MATSUE オフィスのご紹介",
  "date": "2023-07-31T03:05:55.000Z",
  "slug": "entry/2023/07/31/120555",
  "tags": ["medley"],
  "hero": "./2023_07_31.png",
  "heroAlt": "Abount Tech Studio MATSUE Office"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`はじめに`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0feddb1628897345eab64934dde7b6e8/21b4d/_dev202307_003.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC40lEQVQozx3R209bBQCA8ZNo5mK2uXZbBjSRIFEnC0+7GJ3xsqjxkj3sQY2JUwluY8AUKR2MbUEIdIOwiTMag7AXxmCYcLM30y4FWpo1bU9PT89pS0tb2gJrWFkkusQsWz4T/oFf8uUTTtbWYWgxYDON093VyYzDjnfeTUSJsLK8QjqZxOMTiSzEudLdg9lsIqoEkf1uQt5ZJO8sit+FZ9bOmdp6hA/ONFFceRjj5U66jZ1Y/5zEd9eF0znHSiaDLAX4tvpraquraWxuxXHnDmosgSiFEYMywZCCKCn4gipnG5oQvu8foeTQW+j1ekzjY/jdM/jn54jKQXJLKbxuJ6au77Bdu0jr+Qu4PF7iiQSqEkIMBAiIQSRZYWJymuOffYHQPvYXL735IXp9E082CmQXwmRiIZaiYfK5LPlMgvDNXhbG+1nNLbGSTVNYXWR9NU4iKhMMhpCVBX4bGKPq0jCCccJJxTvH0Dc28fB+npQaYlH2sxxXcVjMHP/0BJdqqrjR2cLJugayiRCPNzL89yDJWi5GLBpBVtNYLTdpv2pEaBuxsP/tjzHoDTwsrG2CKUUkpUocfu0owhYtmuJyikv3ITyzh46ODniUZ+NehH/WkpvjIlGVoeGvcFrfRWgevE3FG+/RbDjPv4V1koq0me2yW9HsLUNb9AK7isvZrXuRrZpS3v/oGIWMH9lrJ+x18CCfZTkdo6XlNG3n9iPU9vajq3yVupp6/l7OEfF5uBeX+PXHXoSntrNDq0O7CZezdWcJ+yoP4LLdYvB6Oz1tjXQ019D3Qz2TXV8y0fMNws99l+nrOodl5BoZeZq45zaLrlEuNJyi7PkydmqL2KYp4bndpWzZXsQe3cvMmQZQ3beQZ4aYN/+OZegK5p4q7g60ItiGrqI6BlgN/UEqMIpoH8Qx+hNTN7oRh40crHiFp5/dxTaNjiOHXqfq809Yj1q5r9pI+aZI+6dZ8k4gTV1n8peL/A9CWTJ4QaUC3wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202307 003",
            "title": " dev202307 003",
            "src": "/static/0feddb1628897345eab64934dde7b6e8/c1b63/_dev202307_003.png",
            "srcSet": ["/static/0feddb1628897345eab64934dde7b6e8/5a46d/_dev202307_003.png 300w", "/static/0feddb1628897345eab64934dde7b6e8/0a47e/_dev202307_003.png 600w", "/static/0feddb1628897345eab64934dde7b6e8/c1b63/_dev202307_003.png 1200w", "/static/0feddb1628897345eab64934dde7b6e8/21b4d/_dev202307_003.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`みなさん、こんにちは。医療プラットフォーム本部プロダクト開発室第三開発グループ グループマネージャの太田です。レセコン（電子カルテの機能のうち主に会計など診療報酬の計算をするソフトウェア）新規開発プロジェクトでレセコンの開発を行っています。`}</p>
    <p>{`2021 年 9 月 1 日に Tech Studio MATSUE オフィス（以下松江オフィス）が設立された際、他の初期メンバー 4 人と一緒にメドレーに入社しました。`}</p>
    <p>{`記事を読み終わられた際は、1 人でも多くの方が松江オフィスに興味を持っていただけると嬉しいです。`}</p>
    <h1>{`松江オフィスでやっていること`}</h1>
    <p>{`松江オフィスではレセコン新規開発プロジェクトとして、`}<a parentName="p" {...{
        "href": "https://clinics-cloud.com/karte"
      }}>{`CLINICS カルテ`}</a>{` に内包されるレセコン部分の開発を Ruby on Rails で行っています。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/15b4ddae63c11e436c0861be9a625eed/21b4d/_dev202307_001.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAACzklEQVQozx3D308bBQDA8fPBxGdNfHOJT+jmAgbMkCKLMCmMX45OGRk1mqHWudUZiMhkG9moTkcyB2ptWaHQUri2dy29/qDX63HX8qusrPxSSfag8R/5mvhJPkLWWCeezhKWJTyeaWZnfyMs+vH53Mz5PSwszLAkLrCqZYilM4hxBb1gsL5ZpLhRwCzoaHmVRGqF5WgEYXf/gGBCJb21z5KSRVINlPwaATFEOBZmQQzgnvMyM+/j8fxjJn+ZZuTufb6f8jI1G2QpliCRUdH0PJquI+yUy6xk8+RLe/jEOJGsifn0CHcoSii1hv3OONabTm66xnF88QFvvVPPq9UNWM73Uv1uD32OIebDEklVRTd0hHLlgFLlkI3dI/TSPptPK/zz7zP2jv8imC7ScuU7GqznGB4b4nPHRU7WvEFVbSNNHRc5ZbFy6TMnSlYlb5hsl7YR9g6fUaocs/XH3xTLhxwdlTn+s8T2k3WS5hZ3pt08dA0Qlmd4OOmk+s3XeeVkHbXnbJyoaWbg+hja9j7mzgFPDo4RpIhMOJ4nsVZCTmuIcpxgVMK/HEFUMvgjMSRZJpRUefDoR05XV/H8iyd4uaqe18600nXpUx64F/nZK/JrMI0Q8P7AxIQLz1wUz7yMNxDHL6aJKmssSxlWkho5fQNRlLh1e5RTp6sQhBcQnnuJOksLF/rtOG58S1uvnVbbIILkdxGYmkBLKuRSKbTMKrqqsWkWKObS7Bg5dosa0twUk64RRocH6Xn/Av39H9Pc0k5Xbx8DH12hy9aH86shhMCjb5C89zFTEkYmgZFbZdM0EBcD3Bu/zdc3nFy/5sAxaOd8+3u0Wc8yOuygUsywKotcvmzH0tpDTZ2FfpsN4fd7V4l57lJQQuiJCLoSY/zWGGcaz1L7diN1DY3UNzXRbLXS2d1JR2fH/8N+D/FFH9eufkn3h5/Q3tbNT2Mj/AeD/RaDyyUxaAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202307 001",
            "title": " dev202307 001",
            "src": "/static/15b4ddae63c11e436c0861be9a625eed/c1b63/_dev202307_001.png",
            "srcSet": ["/static/15b4ddae63c11e436c0861be9a625eed/5a46d/_dev202307_001.png 300w", "/static/15b4ddae63c11e436c0861be9a625eed/0a47e/_dev202307_001.png 600w", "/static/15b4ddae63c11e436c0861be9a625eed/c1b63/_dev202307_001.png 1200w", "/static/15b4ddae63c11e436c0861be9a625eed/21b4d/_dev202307_001.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`医療機関でどちらも使われるものですが、電子カルテがドクター向けのアプリケーションなのに対して、レセコンは事務員さん向けのアプリケーションとなります。`}</p>
    <p>{`レセコンでは電子カルテに登録された診療内容を元に診療報酬の計算を行い、日々の業務としては請求書兼領収書、診療費明細書、処方箋、日報の作成を行い、月次の業務としては、保険者に対しての請求データの作成などの処理を行っています。診療報酬とは我々が医療機関を受診した際に受けた医療行為について、その対価として医療機関に支払われる費用です。医療行為１つ１つに点数が設定されており、この点数を「1 点=10 円」として診療報酬の計算を行います。`}</p>
    <p>{`電子カルテとレセコンの関連性は強く、2 つのアプリケーションが近い関係であればあるほど、CLINICS の顧客に対してより価値の高いサービスの提供が可能となります。`}</p>
    <p>{`これらの問題を解決すべく、`}<strong parentName="p">{`Sustainable （維持し続けられる）、Reasonable （合理的である）、Improvable （改善し続ける）、Manageable （管理しやすい）を基本コンセプトにレセコン新規開発プロジェクトが始まり松江でレセコンの開発が行われることになりました。`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9f28e86fd85b761c99d675c86212ceaf/21b4d/_dev202307_002.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC40lEQVQozwXBa09TBwCA4ZPsS2PIWKISjWLEVLMyluoQpcjQqZiZZRdL6NQNBSpV7i0IWDjYUmxnVwWq2HpYgdNCKZFioSKOCQiLE7BMlBSakahL9m37Ee+eR+jvMuO9Wc+QZGNswElflxlfp0h/dzshn4t20YipuozayktUXCmltPhHLpwv5Nw5HeNDXWzExngfn+P9+iLvEssIPbcakFx1SK4qvM4q/G4TgbsNDNxrpedOKyfyj5F3NAeV+gCZ2Rq+PPUFGYfy+DjrFKLYxN/xaeLLs6y/WSDx5iXCWNDNA38HQ7848PtuEx3xEA17eBqVsXlb0dXWUlLThK68EV2NlbJKE3fbRCa9LgKdVv58PsnbRIzVVwvEV2IIK8+CLD4N8ThoZuphG/GlMf5Zm+CP2RGM9XW0DM5R3h3FKD3BHFzgB4uPboeNZ31OfrK2kp2vQ5ZlNtZXeb28hDA/IfP7r8OEPTWM9jTwckbm1cx95idlzNetNA/Oo/dOo/fOYBpeRecIk1tQgSZfS6o6n63px9mRcZxIdJK3f60hdIgGOiwVRHpdPBy8j9spYiw+Tan2CBeLvmffZ0e4fNWKzT2AsyfMdwaRbQe+Ylt6Limqz9mpPoli12FKq1tIrK4gBJzluJqKWAr/zBPZykifnbAk4r9xnheBOvRnv+Hrk0d5MSyxNuHHbjKg02pRHTxBsjKHzcpsktI0pGefxn3LhVBb8i2/+ZqJRywkIs1sjLfw32w7/07beDdqZNpTg6WykNvmy0iOJsI+Dx32G2xX5fJh2iGS07LYlJpJiiqPxvpGhPpLhYy4G1gI2YmNdxN7JPH8US9zo148jkam5JtM9bYx228h0mniml6LoegsHyk1JO3OQrH9ExQ795Os1FBSUoZgMhRhv1bNlOxicdzHUCiIFAgxGuqnuFhPZ3sLVVcMXDeW8enBXFL2qElVZrB1Xw6KHWo+2LIXxa5MktKyOFOg43+PVu8TRENTfQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202307 002",
            "title": " dev202307 002",
            "src": "/static/9f28e86fd85b761c99d675c86212ceaf/c1b63/_dev202307_002.png",
            "srcSet": ["/static/9f28e86fd85b761c99d675c86212ceaf/5a46d/_dev202307_002.png 300w", "/static/9f28e86fd85b761c99d675c86212ceaf/0a47e/_dev202307_002.png 600w", "/static/9f28e86fd85b761c99d675c86212ceaf/c1b63/_dev202307_002.png 1200w", "/static/9f28e86fd85b761c99d675c86212ceaf/21b4d/_dev202307_002.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`アプリケーション側で使いやすい API の開発を効率的に進めています。これには、アプリケーション側の開発チームとの関係強化が必須です。このため、東京の CLINICS チームとは日々 Slack や Google Meet などを通じて密に連絡を取り合いながら相互理解に努めることで、信頼関係を強化し、効率的に開発ができるよう取り組んでいます。`}</p>
    <h1>{`松江オフィスで利用してる技術`}</h1>
    <p>{`サービスのバックエンドということもあり、特別な技術スタックは使用せずオーソドックスに Ruby on Rails、MySQL を利用しています。
インフラも同様でサービスがスケールすることを念頭において AWS を利用しています。また IaC として Terraform を利用しています。
各人の開発環境も GitHub や Docker といった極めて一般的なものを採用しています。`}</p>
    <p>{`新人を除く 5 人は元々別の会社において新規レセコンとは異なるレセコンの開発に携わっていました。当時は COBOL で開発を行っていましたが、レガシーな言語ゆえ、冗長的なコードになったり、同じようなロジックが複数のコードに存在していたりとメンテナンスに大変苦労していました。`}</p>
    <p>{`現在は Ruby で開発を行っており、Ruby なりの難しさはありますが、`}<strong parentName="p">{`COBOL で同じコードを書いたら 5 倍の量になるなと感じるくらい、当時と比べると開発コストは大幅に下がっていると実感しています。`}</strong></p>
    <h1>{`松江オフィスの雰囲気・文化`}</h1>
    <p>{`松江オフィスがある松江市は中海、宍道湖と大きな湖を 2 つもつことで水の都と言われています。この 2 つの湖を結ぶ大橋川ではシーバス釣りが盛んです。
年に 1 回秋頃にレガッタの大会が行われ、弊社のメンバーもチーム TAGBORTS として参加しています（まだオフィスのメンバーだけではチームが組めないため助っ人をお願いしている状況です…）。去年は 2 回戦敗退と悔しい結果となりましたが、今年は更に上を目指したいと思います。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1197px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3901f2513634f225c29663d03d0d7d80/d326d/_dev202307_007.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACS0lEQVQozz1Qa0/TYBjtP/SDxg8a+YARjSBqIwuXISQywHALjMsuZet1vbx9t17XtV23tV3XdnSwOYwIf8YUEpOTk+fJOScnOYjR/21Hf5343oru2tF9e/jgxA/t+MEK71rBH92/TeHd6t70/615U9X9JXcniOZNdW/69GupkGqKO5W644YzqTs30L4G5kgwE2jfADMRrRFsXwNrJLQSBDpjYI2AmeLJKrQSzhgKqS/hjWFNDxk1pJWAUgZUygElB6Tkk5KPHFUgBjqE1CelgFJCSo1INaL0uNZM2FbC6ENSjUk1xqUQq/cx6GOiXwS9otAt8B3k2YvnL1+/eTUzOzP7YW4e/fAp8/Yj+n5xeR7NotmfS5uHn1d3F75kV7/v7eSpnRN665jYzhO5E/zHMY4cXjAlWt4/I9c2D/JlLl+q7eerO0fl3MHF3OIyupZbzx1l1rdXNnaXsttz898y2a2vmY0FdOXdAorsnVIN+0pzJ5o71tPNJkpvLHWuJWckNAe8EQLrimsOWD2gJPeSt2jJJaVete5UgI1AK4JWDK1YNCOxFQIj5PU+p/ms5rGqR8ku3ujgsE2INgEdHDoVYGG8WeKMAqMhwAihGUHzMWxGT2FW8xnVoxSXlLtEo1MV2xXRrog2JpiYYJa5VpHRz2kVEYwBMELRCIExEJpBmlQ9Rkk7KblHPNZWRAsDVlkwS7xR4prFmn5OqaekjHCax2peyqrLKD1a7pGNDlF3CNjGRbsqWpeCWeaNMqsXWa3AqAVaOaPlPCmd4I1/NiO60Jmuc0gAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202307 007",
            "title": " dev202307 007",
            "src": "/static/3901f2513634f225c29663d03d0d7d80/d326d/_dev202307_007.png",
            "srcSet": ["/static/3901f2513634f225c29663d03d0d7d80/5a46d/_dev202307_007.png 300w", "/static/3901f2513634f225c29663d03d0d7d80/0a47e/_dev202307_007.png 600w", "/static/3901f2513634f225c29663d03d0d7d80/d326d/_dev202307_007.png 1197w"],
            "sizes": "(max-width: 1197px) 100vw, 1197px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`松江オフィスは事務所のような雰囲気は一切なく、都会的なデザインとなっています。内装は設計・施工を行っていただきました `}<a parentName="p" {...{
        "href": "https://trailheads.jp/works/tech-studio-matsue/"
      }}>{`トレイルヘッズ様の HP`}</a>{` にてご確認いただくことが可能です。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/73ff05c43dc692d74407c8447438e652/c58a3/_dev202307_005.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAgQA/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAG2VLI6PV//xAAaEAADAQEBAQAAAAAAAAAAAAABAgMAEQQS/9oACAEBAAEFAmqFzejmnQ0AkGZ5BV+eb//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABkQAAIDAQAAAAAAAAAAAAAAAAARARAxIf/aAAgBAQAGPwLYGPCXXD//xAAaEAEBAQADAQAAAAAAAAAAAAABEQAxQVGR/9oACAEBAAE/IbI2OrnAAb1cp9pMxLk8wyT5m8U3/9oADAMBAAIAAwAAABBcz//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPxCNf//EABYRAQEBAAAAAAAAAAAAAAAAAAARAf/aAAgBAgEBPxDaj//EAB0QAQACAgIDAAAAAAAAAAAAAAEAESFBMVFxodH/2gAIAQEAAT8Qb3DoGN7EJQKeI0gowG+vsJxSY09xJKDGdIbtHOJ//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202307 005",
            "title": " dev202307 005",
            "src": "/static/73ff05c43dc692d74407c8447438e652/e5166/_dev202307_005.jpg",
            "srcSet": ["/static/73ff05c43dc692d74407c8447438e652/f93b5/_dev202307_005.jpg 300w", "/static/73ff05c43dc692d74407c8447438e652/b4294/_dev202307_005.jpg 600w", "/static/73ff05c43dc692d74407c8447438e652/e5166/_dev202307_005.jpg 1200w", "/static/73ff05c43dc692d74407c8447438e652/c58a3/_dev202307_005.jpg 1500w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/948edc06aded5c2f6bfe767567635be9/c58a3/_dev202307_006.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFA//EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAGmrkuVBQT/xAAZEAEAAwEBAAAAAAAAAAAAAAABAgMRABL/2gAIAQEAAQUCnnDXpWIuxYR6tfP/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAgMAAAAAAAAAAAAAAAAAASExERIy/9oACAEBAAY/AjpIhi2nJRZ//8QAGxABAAMAAwEAAAAAAAAAAAAAAQARIUFhgaH/2gAIAQEAAT8hBdPYpfdAiKPMvg1phHOkoQoGT//aAAwDAQACAAMAAAAQEP8A/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8QV//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/EIj/xAAdEAEBAAICAwEAAAAAAAAAAAABEQAhMVFBYZGx/9oACAEBAAE/EB2hsjr3lPQMRO3vPH2w0fmKeUDxQ4x2w0xb+4F9qgz/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202307 006",
            "title": " dev202307 006",
            "src": "/static/948edc06aded5c2f6bfe767567635be9/e5166/_dev202307_006.jpg",
            "srcSet": ["/static/948edc06aded5c2f6bfe767567635be9/f93b5/_dev202307_006.jpg 300w", "/static/948edc06aded5c2f6bfe767567635be9/b4294/_dev202307_006.jpg 600w", "/static/948edc06aded5c2f6bfe767567635be9/e5166/_dev202307_006.jpg 1200w", "/static/948edc06aded5c2f6bfe767567635be9/c58a3/_dev202307_006.jpg 1500w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`開発用デスクは周囲が気にならないようにパーティションを設置しています。椅子にはアーロンチェアを採用しており、長時間の作業で体に負担がかからないよう配慮されています。`}</p>
    <p>{`東京オフィスとのコミュニケーションは Google Meet か Slack がメインのため、電話がかかってくることもなく、集中して開発に取り組むことができる環境となっています。`}</p>
    <p>{`現在エンジニア 5 名、ディレクター 1 名の計 6 名が在籍しています。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5a8a42dbdecd380bc4ce11e5e9f6c384/21b4d/_dev202307_004.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC6klEQVQozx3N209TBwDA4RMf9mBiZrxtw6VGHEEeQAVFoAiicNoC3RB0UFlbC8XSFtCDNlwktHVg8WAp0FqLNhGGSA0KiGJARY3KTLbo1Bgv0cRLok4iJiaa+PYz8R/4PuHGs1mevP3Aw1dvePnuHWOXphidmGRkbIQ9Dgd1DQ2Ewkc5cSrCyaEhIsMj9J85S+/gGM3jU1T1DTJwcZqr9x9y5+lLhFvPZ3n14SPP3vzP29n3DJ2foFnuQqpvYse2IlqqTAS9rYT6evEFg7R1B/GETuIe/Bf7wX4kt4cLt/5B9gW4/fgFwrm/73F+6ia3Hzzi05fPRM6OU2CwYtntYLqnkdf99XidezHYd1Fd18huZzu6aje/2fYj7fd+C65cu8L0QIiZmRkER1Mr4e4A3k4/oXAY94E2CraXU2yyI9dXc3dQxlZpRmcqI0dbiFK1hdUZeaxSirR7O/AHQxzyH2Z0+AwTk5MINXaJuoYmjBVW9GVmTBYrG9SFJG/MJTFd5NrF0+iNBvILi1gWG8+c+Uv5IS6ZZQkpdHR20+Jpw2i2saXYhKHcjqDcrCUzW41KW0BObj5ZmnxWJKSQmJrFj9HxlOq2UaL7HU1BIVv1Bkp2lLE4Jon45I0cCYXo7evlD1M5mTm5FJcaEVIzRNIzN7EuVcn6NCVxCYksj0skKSUDRUw88xb9zOEDtfSHvbS6GznocmDUl6ArtxMIBJBlmQqLhaxsEU3+rwirktYjqlSo1SrWrF3HoijFN2hNchoLon7h+8VLGfVL/BdxMuGrxWzQcaxrH8d7Omj3+qjYWUmpXo8k1SKKKoTsnE3sc9TwZ3Md0TGxCN/NI2r5SqJjE7CW5HH5SD3PL3TwYtzLpS4HDTVliFotLrcTT5vMX/4WmqRKpNo95OXlImg0aqSqnfjaWzFbbaRtyGDhTwrmzl9CtEJBkZjOgxGZm30uhn0Sd851MRWR6Tzkwely8WR6gOujPVgqbVTZ7XwFrvTcY6VnAMsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202307 004",
            "title": " dev202307 004",
            "src": "/static/5a8a42dbdecd380bc4ce11e5e9f6c384/c1b63/_dev202307_004.png",
            "srcSet": ["/static/5a8a42dbdecd380bc4ce11e5e9f6c384/5a46d/_dev202307_004.png 300w", "/static/5a8a42dbdecd380bc4ce11e5e9f6c384/0a47e/_dev202307_004.png 600w", "/static/5a8a42dbdecd380bc4ce11e5e9f6c384/c1b63/_dev202307_004.png 1200w", "/static/5a8a42dbdecd380bc4ce11e5e9f6c384/21b4d/_dev202307_004.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`松江オフィスではレセコンに関する業務的な質問や Ruby に関するロジック的な質問など誰とでも気兼ねなく行うことができるように、メンバー間のコミュニケーションが取りやすい環境づくりを心がけています。例えば、今年からの取り組みですが、毎日 10 分程度の朝会を行うようにしました。会の目的としては進捗の遅れや、詰まってることなどを話すきっかけづくりです。`}</p>
    <p>{`レセコン開発の性質上、エンジニアも診療報酬の知識のインプットが必要です。このため、ディレクターを中心に診療報酬の勉強会も行っています。`}</p>
    <p><strong parentName="p">{`以前開発していたレセコンを超えるレセコンを作り上げる。それがメンバーの共通の目標です。`}</strong></p>
    <h1>{`松江オフィスでこんな人と働きたい`}</h1>
    <p>{`以下に当てはまる方、大募集中です。`}</p>
    <ul>
      <li parentName="ul">{`プログラミング（技術）が大好きで、それを手段として課題に対して積極的に行動できる`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.medley.jp/team/culture.html"
        }}>{`Our Essentials`}</a>{` に共感できる`}</li>
      <li parentName="ul">{`医療業界に革新を起こす壮大なミッションにチャレンジしてみたい`}</li>
    </ul>
    <p>{`下記のリンクからご応募お待ちしています。`}</p>
    <ul>
      <li parentName="ul">{`中途採用は`}<a parentName="li" {...{
          "href": "https://www.gogo-jobcafe-shimane.jp/job/detail?job_id=790936"
        }}>{`こちら`}</a></li>
      <li parentName="ul">{`2024 年度新卒採用は`}<a parentName="li" {...{
          "href": "https://www.gogo-jobcafe-shimane.jp/job/detail?job_id=888626"
        }}>{`こちら`}</a></li>
    </ul>
    <h1>{`おわりに`}</h1>
    <p>{`簡単ですが、松江オフィスの紹介をさせていただきました。`}</p>
    <p>{`今後も松江オフィスのメンバー一丸となって レセコン の開発を進め、CLINICS チームと協力して稼働医療機関を増やしていき、より患者が医療にアクセスしやすい環境が作れるように取り組んでいきます！`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      